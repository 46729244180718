import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import styles from "./ScrollDown.module.scss";

interface Props {
  className?: string;
}

const ScrollDown = (props: Props) => {
  const { className } = props;

  return (
    <Stack
      className={className}
      direction="row"
      justifyContent="space-between"
      position="absolute"
      bottom={64}
      width="100%"
    >
      <Stack
        direction="row"
        sx={{
          transform: "rotate(90deg)",
        }}
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: -40,
            width: 128,
          }}
        >
          <span className={styles.animation}>
            <FormattedMessage id="global.scrollDown" />
          </span>
          <ArrowForwardIcon sx={{ ml: 1 }} />
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          transform: "rotate(90deg)",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: 16,
            width: 128,
          }}
        >
          <span className={styles.animation}>
            <FormattedMessage id="global.scrollDown" />
          </span>
          <ArrowForwardIcon sx={{ ml: 1 }} />
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ScrollDown;
