import styles from "./Loader.module.scss";

interface Props {}

const Box = () => {
  return (
    <div className={styles.holder}>
      <div className={styles.box} />
    </div>
  );
};

const Loader = (props: Props) => {
  const {} = props;

  return (
    <div className={styles.container}>
      <Box />
      <Box />
      <Box />
    </div>
  );
};

export default Loader;
