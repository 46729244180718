import { Card, Stack, Typography } from "@mui/material";
import { ROUTES } from "services/routes";

interface Props {
  category: string;
  id: string;
  title: string;
  preview: string;
}

const ArticleCard = (props: Props) => {
  const { category, id, preview, title } = props;

  let color = "#FF0000";

  switch (category) {
    case "Web Development":
      color = "#FD2155";
      break;
    case "Life Style":
      color = "#E4EE89";
      break;
    default:
      break;
  }

  return (
    <Card
      raised
      sx={{
        borderTop: `2px solid ${color}`,
        p: 3,
        width: {
          xs: "100%",
          sm: "calc(51% - 32px)",
          md: "calc(51% - 32px)",
          lg: "calc(33% - 32px)",
        },
      }}
    >
      <a href={ROUTES.ARTICLE(id)}>
        <Stack>
          <Typography variant="h6" sx={{ color: color, py: 1 }}>
            {category}
          </Typography>
          <Typography variant="h5" sx={{ pb: 2 }}>
            {title}
          </Typography>
          <Typography variant="body1">{preview}</Typography>
        </Stack>
      </a>
    </Card>
  );
};

export default ArticleCard;
