interface Props {
  className?: string;
}

export const FooterIllustration = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 12 12"
      version="1.1"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g transform="matrix(1,0,0,1.72458,0,-8.69494)">
        <path
          d="M0,6C1.743,5.965 2.316,5.466 3.02,5.287C3.884,5.067 4.728,5.208 5.527,5.78C7.173,6.745 10.122,7.394 12,6L12,12L0,12L0,6Z"
          style={{
            fill: "rgb(17,130,123)",
            fillOpacity: 0.38,
          }}
        />
        <path
          d="M0,6C1.743,5.965 2.316,5.466 3.02,5.287C3.884,5.067 4.728,5.208 5.527,5.78C7.173,6.745 10.122,7.394 12,6L12,12L0,12L0,6Z"
          style={{
            fill: "rgb(45,186,177)",
            fillOpacity: 0.38,
          }}
        />
      </g>
      <g transform="matrix(1,0,0,1.88119,0,-10.5743)">
        <path
          d="M0,8.224C1.767,7.799 1.902,9.691 4.24,7.774C6.604,5.837 8.167,6.109 9.235,6.153C10.874,6.22 11.37,6.922 12,7.408L12,12L0,12L0,8.224Z"
          style={{
            fill: "rgb(30,60,58)",
            fillOpacity: 0.8,
          }}
        />
      </g>
    </svg>
  );
};

export default FooterIllustration;
