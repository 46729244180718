import styles from "./Background.module.scss";

interface Props {}

const Background = (props: Props) => {
  const {} = props;

  return (
    <div className={styles.container}>
      <img className={styles.image} src="/photo-of-me.jpg" alt="photo of me" />
    </div>
  );
};

export default Background;
