import { Button, Container, Stack, Typography } from "@mui/material";
import {
  LinkedIn as LinkedInIcon,
  GitHub as GitHubIcon,
} from "@mui/icons-material";

import styles from "./Footer.module.scss";
import FooterIllustration from "./FooterIllustration";
import { cn } from "services/styles.service";

interface Props {
  className?: string;
}

const Footer = ({ className }: Props) => {
  return (
    <Stack className={cn(styles.view, className)}>
      <FooterIllustration className={styles.illustration} />
      <Container>
        <Stack alignItems="center" pt={12} pb={6}>
          <div className={styles.networks}>
            <Button variant="outlined">
              <LinkedInIcon />
            </Button>
            <Button variant="outlined">
              <GitHubIcon />
            </Button>
          </div>
          <div>
            <Typography variant="body2" className={styles.author}>
              FREDERIC MAMATH ©2024
            </Typography>
          </div>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Footer;
