import { Card, CardActionArea, Typography } from "@mui/material";

interface Props {
  description: string;
  title: string;
  subTitle?: string;
}

const Book = (props: Props) => {
  const { title, subTitle, description } = props;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        width: {
          xs: "100%",
          sm: "47%",
          md: "30%",
        },
        border: "1px solid white",
      }}
    >
      <CardActionArea
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h5">{title}</Typography>
        {subTitle && (
          <Typography variant="body2" fontStyle="italic" pb={2}>
            {subTitle}
          </Typography>
        )}
        <Typography fontSize="16px" pt={2}>
          {description}
        </Typography>
      </CardActionArea>
    </Card>
  );
};

export default Book;
