import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { NotionPageContentOutboundDto } from "generated/model";

export const mapNotionContentToReactComponent = (
  notionContent: NotionPageContentOutboundDto
) => {
  let variant: Variant = "body2";

  switch (notionContent.blockType) {
    case "paragraph":
      variant = "body2";
      break;
    case "heading":
    case "heading_1":
      variant = "h1";
      break;
    case "heading_2":
      variant = "h2";
      break;
    case "heading_3":
      variant = "h3";
      break;
    default:
      break;
  }

  return <Typography variant={variant}>{notionContent.content}</Typography>;
};
