import { Button, Stack, Typography } from "@mui/material";
import Background from "./Background";
import Bookshelf from "components/Bookshelf";
import Workshop from "components/Workshop";
import Footer from "components/Footer";

import styles from "./LandingPage.module.scss";

const LandingPage = () => {
  return (
    <Stack width="100%">
      <Stack
        sx={{
          height: "100vh",
          width: "100%",
          position: "relative",
          minHeight: "600px",
        }}
      >
        <Background />
        <Stack
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            left: "0",
            top: "0",
            backgroundImage:
              "-webkit-linear-gradient(top, rgba(214, 214, 214, 0.3), rgba(29, 29, 29, 1))",
          }}
        />
        <Stack
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            p: {
              xs: 8,
            },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack></Stack>
          <Stack gap={5} justifyContent="center" alignItems="center">
            <Typography variant="h1" color="primary">
              Frederic Mamath
            </Typography>
            <Typography variant="body1" color="primary" fontWeight="600">
              Professional Developer in France
            </Typography>
            <Button href="mailto:fredericmamath@gmail.com" variant="outlined">
              Contact me
            </Button>
          </Stack>
          <Stack>
            <Typography variant="body1" color="primary">
              Paris
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <div>
        <Workshop />
        <Bookshelf />
      </div>
      <Footer className={styles.footer} />
    </Stack>
  );
};

export default LandingPage;
