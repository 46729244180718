import { Card, Stack, Typography } from "@mui/material";

interface Props {
  summary: string;
  title: string;
  writer: string;
}

const Book = (props: Props) => {
  const { title, writer, summary } = props;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        width: {
          xs: "100%",
          sm: "47%",
          md: "30%",
        },
        border: "1px solid white",
        padding: 2,
      }}
    >
      <Stack>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body2" fontStyle="italic" pb={2}>
          {writer}
        </Typography>
        <Typography fontSize="16px">{summary}</Typography>
      </Stack>
    </Card>
  );
};

export default Book;
