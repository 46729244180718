import { ROUTES } from "services/routes";
import styles from "./MagicWall.module.scss";
import { useGetWorks } from "generated/hook";
import Loader from "components/Loader";

const MagicWall = () => {
  const { data, isLoading } = useGetWorks();

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <div>is loading</div>;
  }

  return (
    <div className={styles.view} style={{ marginTop: 90 }}>
      {data.map((item) => (
        <div className={styles.item} key={item.pageId}>
          <a href={ROUTES.WORK(item.pageId)}>
            <div className={styles.button}>
              <img
                src={item.url}
                alt="Work experience"
                style={{ backgroundColor: item.backgroundColor }}
              />
              <div className={styles.circle}>View project</div>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default MagicWall;
