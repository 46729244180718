import { Container, Stack, Typography } from "@mui/material";
import Card from "components/Card";

interface Props {}

const PROJECTS = [
  {
    id: 1,
    title: "Second Brain",
    description:
      "Archive my thoughts and ideas and make them accessible and maintainable from anywhere. The chosen tool that fits my needs perfectly is Notion. So let's become a power user !",
    expectedLearnings: [],
    startedAt: "2024-01-01",
    deadlineAt: "2024-06-31",
  },
  {
    id: 2,
    title: "Notion to React",
    description: "Share my experiences on my blog",
    expectedLearnings: [],
    startedAt: "2024-07-01",
    deadlineAt: "2024-12-31",
  },
  {
    id: 3,
    title: "Compete in a data science competition",
    description: "Improve my data science skills",
    expectedBenefits: [
      "Better understanding of data architecture",
      "Improve decisions based on data",
    ],
    startedAt: "2024-06-01",
    deadlinedAt: "2024-12-31",
  },
];

const Workshop = (props: Props) => {
  const {} = props;

  return (
    <Stack py={4}>
      <Container>
        <Stack gap={4}>
          <Typography variant="h2" color="primary">
            Workshop
          </Typography>
          <Typography variant="body1">
            What's the best way to learn? By doing! Here are some projects I'm
            working on. Feel free to reach out if you want to collaborate or
            have any questions. The number of projects I'm working on is
            restricted to 3 to ensure I can give my best to each one and switch
            between them to keep my mind entertained.
          </Typography>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="space-between"
            gap={4}
          >
            {PROJECTS.map((project) => (
              <Card
                key={`Workshop-Project-${project.id}`}
                title={project.title}
                description={project.description}
              />
            ))}
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Workshop;
