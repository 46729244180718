import { Container } from "@mui/material";
import NotionBlock from "components/NotionBlock";
import Page from "components/Page";
import { useGetWork } from "generated/hook";
import { useParams } from "react-router-dom";

interface Props {}

const WorkItem = (props: Props) => {
  const {} = props;
  const { workId } = useParams();

  if (!workId) {
    return <div>Not found</div>;
  }

  const { data, isLoading } = useGetWork(workId);

  if (isLoading || !data) {
    return <div>is loading</div>;
  }

  return (
    <Page>
      <Container>
        {data.map((notionContent) => (
          <NotionBlock notionContent={notionContent} />
        ))}
      </Container>
    </Page>
  );
};

export default WorkItem;
