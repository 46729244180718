import { Container, Stack, Typography } from "@mui/material";
import Book from "components/Book";

interface Props {}

const BOOKS = [
  {
    id: 1,
    title: "Atomic Habits",
    writer: "James Clear",
    summary: "Building good habits and breaking bad ones.",
  },
  {
    id: 2,
    title: "The rules of everything",
    writer: "Richard Templar",
    summary: "Learn very simple rules to move forward and succeed in life.",
  },
  {
    id: 3,
    title: "Building a second brain",
    writer: "Tiago Forte",
    summary:
      "Method to lift the heavywork on your brain to allow better focus and productivity.",
  },
  {
    id: 4,
    title: "The compound effect",
    writer: "Darren Hardy",
    summary:
      "Learn how to make small changes in your life that will have a big impact in the long run.",
  },
  {
    id: 5,
    title: "Surrounded by idiots",
    writer: "Darren Hardy",
    summary:
      "We are all the idiots of someone else, learn how to communicate with everyone.",
  },
  {
    id: 6,
    title: "100M$ offers",
    writer: "Alex Hormozi",
    summary:
      "Millionaire's entrepreneur's POV to make unresisting offers and its motivations to make it.",
  },
  {
    id: 7,
    title: "How to fail",
    writer: "Elizabeth Day",
    summary: "Don't be afraid to try. You win a lot of things when you fail.",
  },
];

const Bookshelf = (props: Props) => {
  const {} = props;

  return (
    <Stack py={4}>
      <Container>
        <Stack gap={4}>
          <Typography variant="h2" color="primary">
            Bookshelf
          </Typography>
          <Typography variant="body1">
            Growing up as a video game enthusiast, I often found myself so
            engrossed in gaming that I missed many school days, which
            unfortunately left me lacking in basic social skills. Recognizing
            the impact this had on my personal and professional life, I'm now
            focused on reading self-help books to build better habits and fit in
            better with others. My aim is to find a balance where I can still
            enjoy video games, but in a way that's responsible, while making
            good friends and being a positive part of my community.
          </Typography>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="space-between"
            gap={4}
          >
            {BOOKS.map((book) => (
              <Book
                key={`Bookshelf-Book-${book.id}`}
                title={book.title}
                writer={book.writer}
                summary={book.summary}
              />
            ))}
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Bookshelf;
