import { Stack } from "@mui/material";
import { NotionPageContentOutboundDto } from "generated/model";
import { mapNotionContentToReactComponent } from "./NotionBlock.service";

interface Props {
  notionContent: NotionPageContentOutboundDto;
}

const NotionBlock = (props: Props) => {
  const { notionContent } = props;

  return <Stack>{mapNotionContentToReactComponent(notionContent)}</Stack>;
};

export default NotionBlock;
